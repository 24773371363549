import { Component, OnInit, Self, ViewChild } from '@angular/core';
import { combineLatestWith, Observable, takeUntil, tap } from 'rxjs';
import { DrawerContentLoaderDirective } from '@common/directives';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Navigation } from 'app/core/navigation/navigation.types';
import {
  DrawerPanelSetupService,
  EmployeeService,
  PlatformService,
  UnsubscribeService
} from '@common/services';
import { IDrawerConfig, IEmployeeCurrentUserInfo } from '@common/types';
import { ADMIN_APP_ROLES } from '@common/constants';
import { MatDialog } from '@angular/material/dialog';
import { ProblemManualComponent } from '@common/dialogs/problem-manual/problem-manual.component';
import { FuseNavigationItem } from '@common/fuse/components/navigation';
import { NavigationId } from '../../mock-api/navigation/data';
import { environment } from '../../../environments/environment';
import { NavigationEnd, Router } from '@angular/router';
import { AppRolesEnum } from '@common/enums';

@Component({
  selector: 'com-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  providers: [UnsubscribeService]
})
export class LayoutComponent implements OnInit {
  @ViewChild(DrawerContentLoaderDirective, { static: true })
  public drawerContent!: DrawerContentLoaderDirective;
  public drawerConfig: IDrawerConfig = {
    width: 'w-[320px]'
  };
  public isMobile = this._platformService.isMobile;
  public isMenuOpened = !this.isMobile;
  public drawerIsOpened = false;
  public isScreenSmall: boolean;
  public navigation: Navigation;

  /**
   * Constructor
   */
  constructor(
    private _navigationService: NavigationService,
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private _drawerPanelService: DrawerPanelSetupService,
    private _employeeService: EmployeeService,
    private _platformService: PlatformService,
    private _matDialog: MatDialog,
    private _router: Router,
    @Self() private readonly _unsubscribeService: UnsubscribeService
  ) {
    this.initMenuOpened();
    this.routerSub();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  public ngOnInit(): void {
    // Subscribe to navigation data
    this._navigationService.navigation$
      .pipe(
        combineLatestWith(this._employeeService.employeeCurrentUserInfo$),
        takeUntil(this._unsubscribeService)
      )
      .subscribe(([navigation, employeeCurrentUserInfo]) => {
        this.navigation = {
          ...navigation,
          default: this.canViewStat(
            navigation.default.filter(
              (item) =>
                ADMIN_APP_ROLES.includes(employeeCurrentUserInfo?.appRole?.keyCloakName) || !item.admin
            ),
            employeeCurrentUserInfo
          )
        };
      });

    // Subscribe to media changes
    this._fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeService))
      .subscribe(({ matchingAliases }) => {
        // Check if the screen is small
        this.isScreenSmall = !matchingAliases.includes('md');
      });

    this._drawerPanelService.component$.subscribe((res) => {
      this.drawerContent.viewContainerRef.clear();
      if (res) {
        const { component, input, config } = res;
        this.drawerConfig = config;
        this.drawerIsOpened = true;
        const drawerComponent = this.drawerContent.viewContainerRef.createComponent(component);
        Object.keys(input).forEach((key) => {
          drawerComponent.instance[key] = input[key];
        });
        // this._drawerPanelService.outputInstance$ = new Subject()
        this._drawerPanelService.instance.emit(drawerComponent.instance);
        drawerComponent.instance.closeDrawer.subscribe(() => {
          this.onEventDrawerClosed();
        });
      }
    });
  }

  private initMenuOpened(): void {
    this.isMenuOpened = !this._router.url.startsWith('/call/');
  }

  private routerSub(): void {
    this._router.events
      .pipe(
        tap((event) => {
          if (event instanceof NavigationEnd) {
            this.initMenuOpened();
          }
        }),
        takeUntil(this._unsubscribeService)
      )
      .subscribe();
  }

  private canViewStat(
    items: FuseNavigationItem[],
    userInfo: IEmployeeCurrentUserInfo | undefined
  ): FuseNavigationItem[] {
    if (userInfo?.appRole?.keyCloakName === AppRolesEnum.MEETING_ROOM) {
      const corrected = userInfo?.hasAccessToSystemStat
        ? [items.find((i) => i.id === NavigationId.HealthStat)]
        : [];

      return [...corrected, items.find((i) => i.id === NavigationId.Calendar)];
    }

    if (userInfo?.hasAccessToSystemStat) {
      return items;
    }

    return items.filter((i) => i.id !== NavigationId.HealthStat);
  }

  public toggleMenu(): void {
    this.isMenuOpened = !this.isMenuOpened;
  }

  public onEventDrawerClosed(): void {
    this.drawerContent.viewContainerRef.clear();
    this._drawerPanelService.component$.next(null);
    this._drawerPanelService.instance.next(null);
    this.drawerIsOpened = false;
    this.drawerConfig = null;
  }

  public onHelp(): void {
    this.openInstruction().pipe(takeUntil(this._unsubscribeService)).subscribe();
  }

  openFaq() {
    window.open(`${environment.wiki}/pages/viewpage.action?pageId=49088041`, '_blank');
  }

  private openInstruction(): Observable<void> {
    return this._matDialog
      .open(ProblemManualComponent, {
        panelClass: ['committees-app'],
        width: '730px',
        minWidth: '730px'
      })
      .afterClosed();
  }
}
